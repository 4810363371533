import React, { Fragment } from 'react';
import Icon from '@material-ui/core/Icon';
import useWorkOrderStyles from "../../workOrderStyles";
import { IAttachment, IAttachmentField, IFieldOption, IFileFieldOption, IPageField } from '../../../../../models/appDetails';
import { WorkOrderFieldValue } from '../../../../../models/workOrderFieldValue';
import { Button, Grid, GridSize, InputAdornment, TextField } from '@material-ui/core';
import AttachmentsEditor from './attachmentEditor';
import EditIcon from '@material-ui/icons/Edit';
import MessageDialog from '../../../notifications/MessageDialog';

interface Props {
	field: IPageField;
  initialValue: {
    files: IFileFieldOption[] | null | undefined,
    zipFile: IFieldOption | null | undefined
  };
  gridSize: GridSize | boolean;
  isReadOnly?: boolean;
  setFormValue: (fieldValue: WorkOrderFieldValue) => void;
  setIsMissingDispatcher: (name: string, 
    dispatcher: React.Dispatch<React.SetStateAction<boolean>>) => void;
}

const AttachmentField: React.FC<Props> = ({
  field,
  initialValue,
  gridSize,
  isReadOnly,
  setFormValue,
  setIsMissingDispatcher
}) => {
  const [value, setValue] = React.useState<{
    files: IFileFieldOption[] | null | undefined,
    zipFile: IFieldOption | null | undefined
  }>(initialValue);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [openDialogMessage, setOpenDialogMessage] = React.useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = React.useState<string>('');
  const [isValueMissing, setIsMissing] = React.useState<boolean>(false);
  const classes = useWorkOrderStyles();
  
  const inputRef = React.createRef<HTMLInputElement>();
  const key = `${field.guid}-${field.name}`;
  const icon = field.icon;

  const KILOBYTE = 1024;
  const MEGABYTE = KILOBYTE * KILOBYTE;
  const GIGABYTE = MEGABYTE * KILOBYTE;
  const MAX_FILE_SIZE_MB = 4;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * MEGABYTE;

  const formatFileSize = (sizeInBytes: number) => {
    let fixDecimals = 3;
    if (sizeInBytes < KILOBYTE) {
      return `${sizeInBytes} B`;
    } else if (sizeInBytes < MEGABYTE) {
      return `${(sizeInBytes / KILOBYTE).toFixed(fixDecimals)} KB`;
    } else if (sizeInBytes < (GIGABYTE)) {
      return `${(sizeInBytes / (MEGABYTE)).toFixed(fixDecimals)} MB`;
    } else {
      return `${(sizeInBytes / (GIGABYTE)).toFixed(fixDecimals)} GB`;
    }
  };

  const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
 
    if (e.target.files?.length == 0) {
      return
    }

    try {
      if (!value.files) value.files = [];

      let totalSize = 0;

      for (const file of (e.target.files || [])) {
        totalSize += file.size;
      }

      for (const file of (value.files || [])) {
        totalSize += file.originalSize ? file.originalSize : 0;
      }

      if(totalSize > MAX_FILE_SIZE_BYTES) {
        let message = `Attached file(s) too large: ${formatFileSize(totalSize)} <br> Your attached file(s) exceed ${MAX_FILE_SIZE_MB} MB in total file size.  Please update and try again.`;
        setDialogMessage(message);
        setOpenDialogMessage(true);
        return;
      }

      for (const file of (e.target.files || [])) {
        const base64 = (await WorkOrderFieldValue.toBase64(file)).split(",");
        value.files.push({ label: file.name, value: base64[1], new: true, originalSize: file.size });
      }
      
      const fieldValue = await new WorkOrderFieldValue(field, value.files).withZipValue();
      
      if (inputRef.current)
        inputRef.current.value = "";

      setFormValue(fieldValue);

      setValue({
        files: fieldValue.attachments,
        zipFile: fieldValue.getFieldValue()
      });
      
      if (fieldValue.isRequired && fieldValue.isNull) {
        setIsMissing(true)
      } else {
        setIsMissing(false)
      }
    } catch (error) {
      console.error("there was an error preparing the files", error);
    }
  }

  const closeDialog = async (changes?: IFileFieldOption[]) => {
    if (changes != null) {
      const fieldValue = await new WorkOrderFieldValue(field, changes).withZipValue();
      setFormValue(fieldValue);
      setValue({
        files: fieldValue.attachments,
        zipFile: fieldValue.getFieldValue()
      });
      
      if (fieldValue.isRequired && fieldValue.isNull) {
        setIsMissing(true)
      } else {
        setIsMissing(false)
      }
    }

    setOpenDialog(false);
  }

  setIsMissingDispatcher(field.name, setIsMissing);

  const renderAdornments = () => {
    return (
      <Fragment>
        <InputAdornment key={`${key}-ia`} id={`${key}-ia`} position="end">
          {(value.files ?? []).length > 0 
            ? <EditIcon color="disabled" fontSize="small" className={classes.cursorPointer} onClick={() => setOpenDialog(true)} /> 
            : null
          }          
          <Icon color="disabled" fontSize="small" className={`${classes.attachmentIcon} ${(value.files ?? []).length > 0 ? classes.cursorPointer : ""}`} 
            onClick={() => {
              if (!field.readOnly && (value.files ?? []).length > 0) {
                inputRef.current?.click()
              }
            }}>{icon}</Icon>
        </InputAdornment>
      </Fragment>
    )
  }

  const getFieldClasses = () => {
    let classNames = field.readOnly ? classes.disabledAttachmentField : classes.attachmentField;

    if ((value.files ?? []).length == 0) {
      classNames += " " + classes.attachmentFieldPointer;
    }

    return classNames;
  }

  const renderDialog = () => {
    if ((value.files ?? []).length > 0 && openDialog) {
      return (
        <AttachmentsEditor
          field={field}
          files={[...value.files as IFileFieldOption[]]}
          open={openDialog}
          closeDialog={closeDialog}
          key={`${key}-attachment-editor-component`}/>
      )
    }

    return null;
  }

  const renderFileNames = () => {
    if(!value){
      return '';
    }

    if(value.zipFile?.label){
      return value.zipFile.label;
    }

    if(Array.isArray(value.files) && value.files.length > 0){
      return value.files.map(f=> f.label || '').join(",");
    }

    return '';
  }

  const handleCloseDialog = () => {
    setOpenDialogMessage(false);
    setDialogMessage('');
  };

  return (
    <Fragment key={`${key}-fragment`}>
      <MessageDialog
        open={openDialogMessage}
        title='Your upload/attachment file size is too large'
        body={dialogMessage}
        onClose={handleCloseDialog}
      />
      <Grid key={`${key}-item`} item xs={gridSize} className={!field.show ? classes.hidden : ""}>
        <input
          // accept="image/*"
          ref={inputRef}
          className={classes.hidden}
          id={`attachment-${key}`}
          key={`attachment-${key}`} name={`attachment-${field.name}`}
          multiple
          type="file"
          onChange={(e) => handleOnChange(e)}
          />
        <TextField id={key} type="text" key={key} name={field.name}
          className={getFieldClasses()}
          value={renderFileNames()}
          title={renderFileNames()}
          disabled
          required={field.required}
          error={isValueMissing}
          // helperText={isValueMissing ? "" : null}
          // onChange={(e) => handleOnChange(e)}
          label={field.label}
          onClick={() => {
            if (!field.readOnly && (value?.files ?? []).length == 0) {
              inputRef.current?.click()
            }
          }}
          fullWidth
          InputLabelProps={{ shrink: ((value?.files ?? []).length > 0 ? true : false) }}
          InputProps={{
            endAdornment: renderAdornments()
          }}
        />
      </Grid>
      {renderDialog()}
    </Fragment>
  )
  
}

export default AttachmentField;